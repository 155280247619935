import {deleteQuery, getQuery, postQuery, putQuery} from './hooks';
import {CodesQueryList, GroupsQueryList} from '../constants/api';
import {
  addGroupGuestsReq,
  DeleteGuestReq,
  getCodeGroupReq,
  GetEmailTemplateReq,
  getGroupGuestsReq,
  getListPromoCodesReq,
  SendEmailsGuestReq,
  sendPromoCodeReq,
  updateGroupGuestsReq,
  updatePromoCodeReq,
} from './types/groups';

export const getListCodeGroups = async () => await getQuery(GroupsQueryList.getListCodeGroups());

export const getCodeGroup = async ({groupId}: getCodeGroupReq) => await getQuery(GroupsQueryList.getCodeGroup(groupId));

export const getListPromoCodes = async ({groupId}: getListPromoCodesReq) =>
  await getQuery(CodesQueryList.getListPromoCodes(groupId));

export const sendPromoCode = async ({groupId, codeId}: sendPromoCodeReq) =>
  await postQuery(CodesQueryList.sendPromoCode(groupId, codeId));

export const updatePromoCode = async ({groupId, codeId, body}: updatePromoCodeReq) =>
  await putQuery(CodesQueryList.updatePromoCode(groupId, codeId)).send(body);
export const deletePromoCode = async ({groupId, codeId}: sendPromoCodeReq) =>
  await deleteQuery(CodesQueryList.deletePromoCode(groupId, codeId));

export const getCodeGroupGuests = async ({groupId}: getGroupGuestsReq) =>
  await getQuery(CodesQueryList.getCodeGroupGuests(groupId));
export const addCodeGroupGuest = async ({groupId, body}: addGroupGuestsReq) =>
  await postQuery(CodesQueryList.addCodeGroupGuest(groupId)).send(body);
export const updateCodeGroupGuest = async ({groupId, guestId, body}: updateGroupGuestsReq) =>
  await putQuery(CodesQueryList.updateCodeGroupGuest(groupId, guestId)).send(body);
export const deleteGuest = async ({groupId, guestId}: DeleteGuestReq) =>
  await deleteQuery(CodesQueryList.deleteGuest(groupId, guestId));
export const sendGuestEmails = async ({groupId, body}: SendEmailsGuestReq) =>
  await postQuery(CodesQueryList.sendMultipleGuestEmails(groupId)).send(body);
export const getEmailPreview = async ({groupId, guestId}: GetEmailTemplateReq) =>
  await getQuery(CodesQueryList.getEmailPreview(groupId, guestId));
