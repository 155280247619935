import {TITLE} from '../../ui-kit/constants';

export const SendConfirmModal = {
  title: 'Are you sure you want to send this Promo Code?',
  content:
    'Please make sure the email address is correct. With this action, your guest will receive an email with this Promo Code. This action cannot be undone.',
};

export const SendSuccessModal = {
  title: 'The Promo Code has been shared successfully!',
  content: `Your guest will receive an email with an ${TITLE} Magic Link that will allow them to use the promo code.`,
};

export const GuestSendConfirmModal = {
  title: (email?: string, send?: string) => `Are you ready to ${send} this promo codes to ${email}?`,
  content:
    'Please make sure the email address is correct. With this action, your guest will receive an email with the promo code. This action cannot be undone.',
};

export const GuestSendSuccessModal = {
  title: (email?: string) => `The Promo Codes has been reshared successfully to ${email}!`,
  content: `Your guest will receive an email with ${TITLE} Magic Link that will allow them to use the promo code.`,
};

export const GuestUpdateSuccessModal = {
  title: `The Guest details was successfully updated!`,
  content: 'Do you also want to resend Promo Codes for this guest?',
};
