import {themes} from '../ui-kit/theme/theme';
import {THEME} from './env';

export const HELP_TITLE = 'How to use your Promo Code Portal';
export const HELP_DESCRIPTION_PT1 =
  'Below you will see details about your contacts and assigned promo codes, along with their send and usage status. You can edit contacts, send/resend codes, and switch between codes and distribution views below.';
export const HELP_DESCRIPTION_PT2 =
  'To add more contacts, enter their names and emails or click "Upload Contacts" to create them in bulk.';
export const HELP_DESCRIPTION_PT3 =
  'If you are manually sharing codes through the list, use the list view and mark codes as shared.';

export const POPULATED_CODES =
  'You can go through the entries for each promo code and review to see if the details are correct. Then you may either send the code individually by clicking on the row, or you can bulk send all by clicking on the “Bulk Send Codes” at the bottom of the page.';

export const TITLE = {
  [themes.enchant]: 'Enchant',
  [themes.coke]: 'Classic Christmas',
}[THEME];
