import styled, {css} from 'styled-components';
import goldOrnaments1 from '../../ui-kit/icons/gold_ornaments1.svg';
import goldOrnaments2 from '../../ui-kit/icons/gold_ornaments2.svg';
import CC_Background from '../../ui-kit/AppLayout/assets/CC_Background_2.png';
import {Typography} from 'antd';
import {LinkButton} from '../../ui-kit/Button';
import {themes} from '../../ui-kit/theme/theme';
const {Text} = Typography;

const encBG = css`
  background-color: ${({theme}) => theme.palette.background.midnightBlue};
  background-image: url(${goldOrnaments1}), url(${goldOrnaments2});
  background-position: left, right;
  background-repeat: no-repeat, no-repeat;
`;

const ccBG = css`
  background-color: ${({theme}) => theme.palette.background.white};
  background-image: url(${CC_Background});
  background-size: cover;
`;

export const WelcomePageWrapper = styled.div`
  ${({theme}) => {
    if (theme.current === themes.enchant) return encBG;
    if (theme.current === themes.coke) return ccBG;
  }}
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(100% - 80px);
`;

export const WelcomeContentWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.white};
  margin-top: 80px;
  margin-bottom: 280px;
  padding: 32px 23px;
  border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  border-radius: 8px;
  width: 548px;
`;

export const WelcomeTitle = styled(Text)`
  font-size: 24px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  line-height: 32px;
`;

export const WelcomeContent = styled.div`
  margin-top: 32px;
`;

export const WelcomeContentText = styled.div`
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 28px;
  font-weight: ${({theme}) => theme.font.weight.light};
`;

export const StyledFileLink = styled.a`
  color: ${({theme}) => theme.palette.common.brown};
  font-weight: ${({theme}) => theme.font.weight.bold};
`;

export const List = styled.ul``;
export const ListItem = styled.li``;

export const ContinueButton = styled(LinkButton)`
  width: 300px;
  height: 40px;
  font-size: 16px;
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
`;

export const FootNote = styled.div`
  font-size: 10px;
  line-height: 12px;
  width: 100%;
  margin-top: 12px;
  text-align: center;
`;

export const FootNoteText = styled.span``;

export const FootNoteBoldText = styled.a`
  color: ${({theme}) => theme.palette.text.primary};
  font-weight: ${({theme}) => theme.font.weight.bold};
`;
