import React, {useState} from 'react';
import {routePaths} from '../../constants/routes';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';
import {ModalLvl, ShareModal} from '../Groups/ShareModal';
import {useSendNotSentEmails} from '../../hooks/emails';

const StyledFooter = styled(Footer)<{$fixed?: boolean}>`
  ${({$fixed}) => ($fixed ? `position: fixed; bottom: 0; left: 0;` : `display: none;`)};
`;

type GroupFooterContainer = {
  onSuccess?: () => void;
  onBulk?: () => Promise<void>;
  sendCount?: string;
};

export const GroupFooterContainer: React.FC<GroupFooterContainer> = ({onSuccess, onBulk, sendCount}) => {
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const {pathname} = useLocation();
  const isGroupPage = pathname.includes(routePaths.groupView);
  const id = isGroupPage ? pathname.split('/')?.[2] : '';
  const {send} = useSendNotSentEmails([id]);
  // const onShareClick = () => setModalLvl(ModalLvl.confirm);
  const onShareClick = () => {
    window.analytics.track('Share Button Clicked', {
      category: 'User Interaction',
      action: 'Click',
      label: 'Bulk Send Codes',
    });
    setModalLvl(ModalLvl.confirm);
  };
  const onShare = async () => {
    await send();
    await onBulk?.();
    onSuccess?.();
  };
  const showReshare = isGroupPage;
  const changeModalLvl = (lvl: ModalLvl) => setModalLvl(lvl);
  const totalQtyNotSentEmails = () => sendCount || '';

  return (
    <>
      <ShareModal
        modalLvl={modalLvl}
        setModalLvl={changeModalLvl}
        onSuccess={onShare}
        totalQtyNotSentEmails={totalQtyNotSentEmails}
      />
      <StyledFooter
        showReshare={showReshare}
        buttonText={'Bulk Send Codes'}
        $fixed={showReshare}
        onReshare={onShareClick}
      />
    </>
  );
};

const FooterContainer = () => {
  return (
    <>
      <Footer />
    </>
  );
};
export default FooterContainer;
