import React from 'react';
import {Modal} from 'antd';
import {getEmailPreview} from '../../queries/groups';
import {EmailPreviewT} from '../../types/emails';
import {EmailPreview} from '../../components/Groups/View/EmailPreview';

export const useEmailPreview = (groupId?: number) => {
  const loadEmailPreview = async (guestId?: string) => {
    if (!guestId || !groupId) return;
    try {
      const res = await getEmailPreview({groupId: String(groupId), guestId: guestId});
      const preview = res?.body as EmailPreviewT;

      console.log('res', preview);
      Modal.info({
        title: 'Email Preview Only',
        content: (
          <EmailPreview emailBody={preview.emailBody} subject={preview.subject} from={preview.from} to={preview.to} />
        ),
        okButtonProps: {
          style: {
            display: 'none',
          },
        },
        closable: true,
        className: 'preview',
      });
    } catch (error) {}
  };

  return {loadEmailPreview};
};
