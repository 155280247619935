import {THEME} from './env';

export const AuthQueryList = {
  logout: () => '/v1/auth/logout',
  login: '/v1/auth/login',
  authorize: () => '/v1/auth/authorize',
  user: () => '/v1/auth/user',
  sendMagicLink: () => '/v1/auth/sendMagicLink',
  magicLinkLogin: () => '/v1/auth/magicLinkLogin',
  //reset pass
  updatePassword: `/v1/auth/resetPassword`,
  requestPasswordReset: `/v1/auth/sendResetPasswordEmail`,
  resetPasswordUsingCode: `/v1/auth/resetPasswordUsingCode`,
  resetPassword: (userId: string) => `/v1/user/${userId}/resetPassword`,
};

export const CodesQueryList = {
  uploadGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/uploadGuestEmails`,
  sendNotSentGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/sendNotSentGuestEmails`,
  getListPromoCodes: (groupId: string) => `/v1/codeGroup/${groupId}/promoCode`,
  getPromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  deletePromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  sendPromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}/send`,
  updatePromoCode: (groupId: string, codeId: string) => `/v1/codeGroup/${groupId}/promoCode/${codeId}`,
  //guests
  getCodeGroupGuests: (groupId: string) => `/v1/codeGroup/${groupId}/guest`,
  addCodeGroupGuest: (groupId: string) => `/v1/codeGroup/${groupId}/guest`,
  updateCodeGroupGuest: (groupId: string, guestId: string) => `/v1/codeGroup/${groupId}/guest/${guestId}`,
  deleteGuest: (groupId: string, guestId: string) => `/v1/codeGroup/${groupId}/guest/${guestId}`,
  sendMultipleGuestEmails: (groupId: string) => `/v1/codeGroup/${groupId}/sendMultipleGuestEmails`,
  getEmailPreview: (groupId: string, guestId: string) => `/v1/codeGroup/${groupId}/guest/${guestId}/emailPreview`,
};

export const GroupsQueryList = {
  getListCodeGroups: () => `/v1/codeGroup?sortBy=id&sortOrder=DESC&brand=${THEME}`,
  getCodeGroup: (groupId: string) => `/v1/codeGroup/${groupId}`,
};
