import React, {useEffect, useState} from 'react';
// import {route} from '../../constants/routes';
import {getTableCodesFilterOptions} from '../../../helpers/codes';
import {CodesCardT, CodeStatusTypes, GenerateCSVResT, GroupDetailTableDataT} from '../../../types/codes';
import {BigCard} from '../../../ui-kit/StaticCard';
import {StyledTableContentWrapper} from '../../../ui-kit/Table/styles';
import {
  CloseSuccess,
  HeadLine,
  HelpDescription,
  HelpPopover,
  HelpRow,
  PageWrapper,
  SuccessBanner,
  ValueItem,
  ValueNameItem,
  ValueWrapper,
} from './styles';
import {GroupDetailTable, ModalLvl} from './Table';
import {TableCards} from './TableCards';
import {CodeGroupT} from '../../../queries/types/groups';
import {Modal} from 'antd';
import {UploadModal} from './UploadModal';
import {TableViews, usePromoCodesActionsT} from '../../../hooks/groups';
import {SelectOptionsT} from '../../../ui-kit/Select';
import {updateTargetT, useSetEmailsT} from '../../../hooks/emails';
import {TooltipIcon} from '../../../ui-kit/Tooltip/styles';
import {
  HELP_DESCRIPTION_PT1,
  HELP_DESCRIPTION_PT2,
  HELP_DESCRIPTION_PT3,
  HELP_TITLE,
  POPULATED_CODES,
} from '../../../constants/text';
import {CloseOutlined} from '@ant-design/icons';

type ViewCodeGroupProps = {
  code?: CodeGroupT;
  codeLoading?: boolean;
  tableData?: GroupDetailTableDataT[];
  listLoading?: boolean;
  onUpload: (file: any, onSuccess?: () => void) => Promise<any>;
  onDeletePromoCode?: usePromoCodesActionsT['onDelete'];
  onSendCode: (id?: string) => Promise<boolean>;
  onSendGuest: (ids?: string[]) => Promise<boolean>;
  onUpdate?: usePromoCodesActionsT['onUpdate'];
  groupsOptions?: SelectOptionsT[];
  onGroupRedirect?: (values?: any) => void;
  emailsData: useSetEmailsT;
  updateTarget: updateTargetT;
  searchPromoCodes: (v: string) => void;
  refetch?: () => void;
  onDelete?: (id?: string) => Promise<boolean>;
  setTableView: (view: TableViews) => void;
  tableView: TableViews;
  modals: {
    helpModal: {
      expanded: boolean | undefined;
    };
    subsequent: boolean | undefined;
    triggerFlow: () => void;
    restoreSubsequent: () => void;
    uploadTriggered: boolean | undefined;
    isOnboarding: boolean;
    restoreOnboarding: () => void;
    triggerOnboarding?: string;
  };
  downloadCSV?: () => GenerateCSVResT;
};

export const GroupView: React.FC<ViewCodeGroupProps> = ({
  codeLoading,
  code,
  listLoading,
  tableData,
  onUpload,
  onSendCode,
  onUpdate,
  groupsOptions,
  onGroupRedirect,
  emailsData,
  updateTarget,
  searchPromoCodes,
  refetch,
  onDelete,
  setTableView,
  tableView,
  onSendGuest,
  onDeletePromoCode,
  modals,
  downloadCSV,
}) => {
  const [filter, setFilter] = useState<CodeStatusTypes | string>('all');
  const [modalLvl, setModalLvl] = useState<ModalLvl>(ModalLvl.closed);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const handleSetFilter = (value: CodeStatusTypes | string) => setFilter(value);
  const options = getTableCodesFilterOptions();
  const {triggerFlow, helpModal, subsequent, isOnboarding, restoreOnboarding, uploadTriggered} = modals;
  const cards: CodesCardT[] = [
    {
      label: 'Total Codes',
      value: String(code?.codesUploaded ?? ''),
    },
    {
      label: 'Codes Sent',
      value: String(code?.codesSent ?? ''),
      tooltip: 'The total numbers of codes that clients send guests.',
    },
    // {
    //   label: 'Codes Applied',
    //   value: String(code?.codesUsed ?? ''),
    //   tooltip: 'The total numbers of guests who open their code emails.',
    // },
  ];

  const cardLineValues = {
    contact: [code?.contactPerson, code?.clientEmailList?.join('\n') || code?.clientEmail],
    ticketTypes: code?.ticketType?.split(','),
  };

  const cardLines = [
    {
      label: 'Contact Details',
      value: (
        <ValueWrapper>
          <ValueNameItem>{cardLineValues.contact[0]}</ValueNameItem>
          <ValueItem>{cardLineValues.contact[1]}</ValueItem>
        </ValueWrapper>
      ),
    },
    {
      label: 'Ticket Types',
      value: (
        <ValueWrapper>
          {cardLineValues?.ticketTypes?.map((el) => (
            <ValueItem key={el}>{el}</ValueItem>
          ))}
        </ValueWrapper>
      ),
    },
    {
      label: 'Details',
      value: (
        <ValueWrapper>
          <ValueItem>{code?.description}</ValueItem>
        </ValueWrapper>
      ),
    },
  ];

  const openUpload = () => {
    //always show template modal
    if (subsequent || true) {
      triggerFlow();
      // restoreSubsequent();
      return;
    }
    setModalLvl(ModalLvl.success);
  };
  useEffect(() => {
    if (uploadTriggered) setModalLvl(ModalLvl.success);
  }, [uploadTriggered]);
  const onCancelUpload = () => setModalLvl(ModalLvl.closed);
  const onUploadSuccess = () => {
    setUploadSuccess(true);
    setModalLvl(ModalLvl.closed);
  };
  const closeBanner = () => setUploadSuccess(false);
  const onAddPromoCodes = async (value: any[]) => await onUpload?.(value);

  useEffect(() => {
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
        modalRender: () => (
          <UploadModal
            onCancel={onCancelUpload}
            onAdd={onAddPromoCodes}
            uploadTriggered={uploadTriggered}
            onUploadSuccess={onUploadSuccess}
            isOnboarding={isOnboarding}
          />
        ),
        width: 460,
        maskClosable: true,
        afterClose: () => {
          setModalLvl(ModalLvl.closed);
        },
        style: {pointerEvents: 'all'},
      });
    }
    if (modalLvl === ModalLvl.closed) Modal.destroyAll();
  }, [modalLvl]);

  useEffect(() => {
    if (isOnboarding && uploadSuccess) {
      Modal.success({
        onOk: () => {
          setUploadSuccess(false);
          restoreOnboarding();
        },
        afterClose: () => {
          setUploadSuccess(false);
          restoreOnboarding();
        },
        okText: 'Done',
        title: 'Your codes have been populated.',
        content: <>{POPULATED_CODES}</>,
        width: 460,
        style: {pointerEvents: 'all'},
        centered: true,
      });
    }
  }, [isOnboarding, uploadSuccess]);
  return (
    <PageWrapper>
      {uploadSuccess && !isOnboarding && <Success close={closeBanner} />}
      <HeadLine>
        <Help expanded={helpModal.expanded || undefined} />
      </HeadLine>
      <BigCard
        cardTitle={code?.clientName}
        lines={cardLines}
        additionalInfo={[
          'Created By:',
          `${code?.user?.firstName} ${code?.user?.lastName}, ${code?.department} (${code?.groupType})`,
        ]}
        loading={codeLoading}
        titleOptions={groupsOptions}
        defaultOption={groupsOptions?.find((el) => String(el?.value) === String(code?.id))}
        titleOnChange={onGroupRedirect}
        logo={code?.clientLogoUrl}
      />
      <StyledTableContentWrapper>
        <TableCards
          cards={cards}
          createdAt={code?.createdAt}
          updatedAt={code?.updatedAt}
          filterValue={filter}
          setFilter={handleSetFilter}
          filterOptions={options}
          onUpload={openUpload}
        />
        <GroupDetailTable
          promoCodes={tableData}
          listLoading={listLoading}
          onSendCode={onSendCode}
          onUpdate={onUpdate}
          emailsData={emailsData}
          updateTarget={updateTarget}
          searchPromoCodes={searchPromoCodes}
          refetch={refetch}
          onDelete={onDelete}
          setTableView={setTableView}
          tableView={tableView}
          onSendGuest={onSendGuest}
          onDeletePromoCode={onDeletePromoCode}
          totalCodes={code?.codesUploaded}
          usedCodes={code?.codesAssigned}
          downloadCSV={downloadCSV}
          groupId={code?.id}
        />
      </StyledTableContentWrapper>
    </PageWrapper>
  );
};

const Help: React.FC<{expanded?: boolean}> = ({expanded}) => {
  return (
    <HelpPopover
      placement="bottomRight"
      title={HELP_TITLE}
      content={
        <HelpDescription>
          {HELP_DESCRIPTION_PT1}
          <br />
          <br />
          {HELP_DESCRIPTION_PT2}
          <br />
          <br />
          {HELP_DESCRIPTION_PT3}
        </HelpDescription>
      }
      trigger="click"
      visible={expanded}>
      <HelpRow>
        Need Help?
        <TooltipIcon />
      </HelpRow>
    </HelpPopover>
  );
};

const Success: React.FC<{close: () => void}> = ({close}) => {
  return (
    <SuccessBanner>
      <p>
        <b>Your contacts have been added.</b>
      </p>
      <p>
        Please review your contact details before sending out their codes. You can edit contacts by clicking “Edit” and
        adjusting their name, email, or code quantities, then clicking “Save.”
      </p>
      <p>
        Once you have verified your contacts, click “Bulk Send Codes” at the bottom of the page to send emails to all
        your new or updated contacts. You can also send codes to a specific contact by clicking “Send” to the right of
        their name.
      </p>
      <CloseSuccess onClick={close}>
        <CloseOutlined />
      </CloseSuccess>
    </SuccessBanner>
  );
};
