export enum uploadGuestEmailsFields {
  guestEmails = 'guestEmails',
}
export type uploadGuestEmailsReq = {
  groupId: string;
  body: {
    guestEmails: File | string;
  };
};

export type sendNotSentGuestEmailsReq = {
  groupId: string;
};
