import {useEffect, useState} from 'react';
import {
  deleteGuest,
  deletePromoCode,
  getCodeGroup,
  getCodeGroupGuests,
  getListCodeGroups,
  getListPromoCodes,
  sendGuestEmails,
  sendPromoCode,
  updatePromoCode,
} from '../queries/groups';
import {getCodeGroupRes, getListCodeGroupsRes, PromoCodeT} from '../queries/types/groups';
import {groupByGuestTableState, promoCodesToTableState, validateEmail} from '../helpers/codes';
import {CodeAccessTypes} from '../ui-kit/helpers/codes';
import {
  CodeGroupGuestT,
  CodeViewFields,
  CodeViewStateChange,
  CodeViewStateT,
  GenerateCSVResT,
  GroupDetailTableDataT,
  GuestT,
  OnUpdateCodeT,
} from '../types/codes';
import {format} from 'date-fns';
import {THEME} from '../constants/env';

export const useGetGroups = () => {
  const [groups, setGroups] = useState<getListCodeGroupsRes>([]);
  const [loading, setLoading] = useState(false);
  const fetch = async () => {
    setLoading(true);
    try {
      const res = await getListCodeGroups();
      if (res) {
        setGroups(res.body as getListCodeGroupsRes);
        setLoading(false);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  useEffect(() => {
    fetch();
  }, []);

  const groupsOptions =
    groups?.length > 1
      ? groups
          .filter((obj) => obj.outboundStatus === 'sent')
          .map((el) => ({label: el?.clientName, value: String(el?.id)}))
      : [];
  return {
    groups,
    refetch: fetch,
    loading,
    groupsOptions,
  };
};

export const useGetCodeGroup = (id?: string, skip?: boolean) => {
  const [group, setGroup] = useState<getCodeGroupRes>();
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const fetch = () => {
    if (!id || skip) return;
    setLoading(true);
    getCodeGroup({groupId: id}).then((res) => {
      if (res?.body?.brand == THEME) {
        setGroup(res?.body as getCodeGroupRes);
        setLoading(false);
        return;
      }
      setNotFound(true);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetch();
  }, [id, skip]);

  return {group, loading, refetch: fetch, notFound};
};

export enum TableViews {
  codes = 'codes',
  guests = 'guests',
}

export const useGetListPromoCodes = (id?: string) => {
  const [tableView, setTableView] = useState<TableViews>(TableViews.guests);
  const [guestData, setGuestData] = useState<GuestT>();
  const [promoCodes, setPromoCodes] = useState<PromoCodeT[]>();
  const [initialPromocodes, setInitialPromocodes] = useState<PromoCodeT[]>();
  const [guests, setGuests] = useState<CodeGroupGuestT[]>();
  const [loading, setLoading] = useState(false);
  const [notSentEmails, setNotSentEmails] = useState('');
  const {fetchGuest} = useGetGuest(id);
  const onSetTableView = (view: TableViews) => setTableView(view);

  const fetch = async () => {
    if (!id) return;
    setLoading(true);
    const guest = await fetchGuest();
    const codes = await getListPromoCodes({groupId: id});
    setPromoCodes(codes?.body);
    setInitialPromocodes(codes?.body);
    setGuestData(guest);
    setGuests(guest?.codeGroupGuests);
    setNotSentEmails(
      codes?.body.filter((el: any) => el?.outboundStatus === CodeAccessTypes.notsent && el.email).length,
    );
    setLoading(false);
  };

  const searchPromoCodes = (v: string) => {
    if (guests) {
      if (v === '') {
        setGuests(guestData?.codeGroupGuests);
        setPromoCodes(initialPromocodes);
        return;
      }

      const searchValues = v.split(',').map((el) => el.toLowerCase().trim());
      const results: CodeGroupGuestT[] = [];

      guestData?.codeGroupGuests.forEach((guest) => {
        const guestName = guest?.guestName?.toLowerCase();
        const guestEmail = guest?.guestEmail?.toLowerCase();

        const searched = searchValues.some((sv) => guestName.includes(sv) || guestEmail.includes(sv));
        if (searched) results.push(guest);
      });
      setGuests(results);

      // if (promoCodes?.some((el) => el.guestName?.toLowerCase().includes(value))) {
      //   setPromoCodes(promoCodes?.filter((el) => el.guestName?.toLowerCase()?.includes(value)));
      // } else if (promoCodes?.some((el) => el.email?.toLowerCase().includes(value))) {
      //   setPromoCodes(promoCodes?.filter((el) => el.email?.toLowerCase().includes(value)));
      // } else {
      //   setPromoCodes([]);
      // }
    }
  };

  useEffect(() => {
    fetch();
  }, [id]);
  const table =
    tableView === TableViews.guests
      ? guests && groupByGuestTableState(guests, initialPromocodes)
      : promoCodes && promoCodesToTableState(promoCodes);
  const {downloadCSV} = useCreateCodesCSV(promoCodes);

  return {
    promoCodes,
    notSentEmails,
    tableData: table?.table,
    loading,
    refetch: fetch,
    usedCodes: table?.used,
    searchPromoCodes,
    tableView,
    onSetTableView,
    downloadCSV: tableView === TableViews.codes ? downloadCSV : undefined,
  };
};

export const useSendCode = (groupId?: string, onSuccess?: () => void, r?: boolean) => {
  const sendCode = async (codeId?: string) => {
    if (!groupId || !codeId) return false;
    try {
      const res = await sendPromoCode({groupId, codeId});
      if (res?.ok && r) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {sendCode};
};

export const useSendGuestEmail = (groupId?: string, onSuccess?: () => void, r?: boolean) => {
  const sendGuest = async (guestIds?: string[]) => {
    if (!groupId || !guestIds?.length) return false;
    try {
      const body = {guestIds: guestIds?.map((el) => Number(el))};
      const res = await sendGuestEmails({groupId, body});
      if (res?.ok && r) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  return {sendGuest};
};

export type usePromoCodesActionsT = {
  onDelete?: (codeId?: string) => Promise<boolean>;
  onUpdate?: (props: {codeId?: string; email?: string; guestName?: string}) => Promise<boolean>;
};

export const usePromoCodesActions = (groupId?: string, onSuccess?: () => void) => {
  const onDelete = async (codeId?: string) => {
    if (!codeId || !groupId) return false;
    try {
      const res = await deleteGuest({groupId, guestId: codeId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  const onDeletePromoCode = async (codeId?: string) => {
    if (!codeId || !groupId) return false;
    try {
      const res = await deletePromoCode({groupId, codeId});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      return false;
    }
  };
  const onUpdate = async ({codeId, email, guestName}: {codeId?: string; email?: string; guestName?: string}) => {
    if (!codeId || !groupId || !email) return false;
    try {
      const res = await updatePromoCode({groupId, codeId, body: {email, guestName}});
      if (res) onSuccess?.();
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  };
  return {onUpdate, onDeletePromoCode, onDelete};
};

export type codeState = {
  name?: string;
  email?: string;
  valid: boolean;
};
export type codeFieldsT = 'email' | 'name' | string;
export type codeStateVal = {key: codeFieldsT; value?: string | number};

export const useEditingCodes = (
  initialState?: GroupDetailTableDataT,
  onUpdate?: OnUpdateCodeT,
  editingId?: string,
  onSuccess?: () => void,
) => {
  const [eCodeState, setEditingState] = useState<codeState>({valid: true});
  const handleEdit = (val: codeStateVal) => setEditingState((prev: any) => ({...prev, [val.key]: val?.value}));

  useEffect(() => {
    setEditingState((prev: codeState) => ({
      ...prev,
      name: initialState?.guestName?.guestName || '',
      email: initialState?.email?.email || '',
    }));
  }, [initialState?.id]);

  useEffect(() => {
    const isValid = !!validateEmail(eCodeState?.email) && (eCodeState.name?.length || 0) > 0;
    setEditingState((prev: any) => ({...prev, valid: isValid}));
  }, [eCodeState.name, eCodeState.email]);

  const onSave = async () => {
    await onUpdate?.({codeId: editingId, email: eCodeState.email, guestName: eCodeState.name});
    onSuccess?.();
    setEditingState({valid: true});
  };

  return {eCodeState, handleEdit, onSave};
};

export type useGuestChangeCodesT = {
  changeCodes: (id?: string) => ((value: string | number) => void) | undefined;
  codes: Record<string, number> | undefined;
};
export const useGuestChangeCodes = (data?: GroupDetailTableDataT[]): useGuestChangeCodesT => {
  const [codes, setCodes] = useState<Record<string, number>>();
  useEffect(() => {
    // initial state
    const Map: Record<string, number> = {};
    data?.forEach((el) => {
      if (!el?.id) return;
      Map[el.id] = Number(el?.code?.used?.length || 0);
    });
    setCodes(Map);
  }, [data?.length]);
  const changeCodes = (id?: string) => (number: number | string) => {
    if (id !== undefined) setCodes((prev) => ({...prev, [id]: Number(number) || 0}));
  };
  return {changeCodes, codes};
};

export const useGetGuest = (groupId?: string) => {
  const fetch = async () => {
    if (!groupId) return;
    const res = await getCodeGroupGuests({groupId});
    return res?.body as GuestT;
  };
  useEffect(() => {
    fetch();
  }, []);

  return {fetchGuest: fetch};
};

export type PromoStateT = Record<string, CodeViewStateT>;

export const useCodesView = (
  tableView: TableViews,
  promocodes?: GroupDetailTableDataT[],
  editingId?: string,
  onUpdate?: usePromoCodesActionsT['onUpdate'],
  setEditingId?: (id?: string) => void,
) => {
  const [promoState, setPromoState] = useState<PromoStateT>();
  const changePromoState: CodeViewStateChange = ({id, value, key}) => {
    let valid = true;
    if (key === CodeViewFields.name && (value?.length || 0) < 2) valid = false;
    if (key === CodeViewFields.email && !validateEmail(value)) valid = false;
    setPromoState((prev) => ({...prev, [id]: {...prev?.[id], [key]: value, valid}}));
  };

  const onUpdatePromo = async (id?: string) => {
    if (!id) return false;
    try {
      const name = promoState?.[id]?.name;
      const email = promoState?.[id]?.email;
      await onUpdate?.({guestName: name, email, codeId: id});
      setEditingId?.('');
      return true;
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    if (tableView === TableViews.codes && editingId) {
      const item = promocodes?.find((el) => el?.id === editingId);
      const name = item?.guestName.guestName || '';
      const email = item?.email.email || '';
      setPromoState((prev) => ({...prev, [editingId]: {name, email, valid: true}}));
    }
  }, [tableView, editingId]);
  return {promoState, onUpdatePromo, changePromoState};
};

const CSV_HEADERS = ['Promo Code', 'Name', 'Email', 'Status', 'Redeemed At'];
export const useCreateCodesCSV = (codes?: PromoCodeT[]) => {
  const downloadCSV = (): GenerateCSVResT => {
    const data =
      codes?.map((el) => [
        el.code,
        el.guestName,
        el.email,
        el.outboundStatus,
        el.redeemedAt ? format(new Date(el.redeemedAt), 'Pp') : '',
      ]) || [];
    return {headers: CSV_HEADERS, data};
  };
  return {downloadCSV};
};
