import React, {useState, useEffect} from 'react';
import {
  LoginFormTitle,
  LoginFormSubtitle,
  LoginFormWrapper,
  SubmitBtn,
  StyledAuthInput,
  StyledAuthFormItem,
  ButtonsWrapper,
  ForgotFormWrapper,
  ErrorLine,
  ErrorText,
  StyledLine,
  StyledOr,
  LoginLinkButton,
} from './styles';
import {Form} from 'antd';
import {Link} from 'react-router-dom';
import {EmailResultLine, ResultText} from '../Forgot/styles';
import {route} from '../../constants/routes';
import {useLoginRes} from '../../hooks/auth';
import {validateEmail, validatePassword} from '../../helpers/helpers';
import styled from 'styled-components';
import {EyeInvisibleOutlined, EyeOutlined} from '@ant-design/icons';

type LoginFormProps = {
  authData: useLoginRes;
  successUpdated?: boolean;
};

const validateMessages = {
  types: {
    email: 'Invalid email. Please enter a valid email.',
    password: 'Invalid password. Please enter a valid password.',
  },
};

const LoginFormForgot: React.FC = () => {
  return <Link to={route.forgot.path}>Forgot password</Link>;
};

export const PasswordLabel: React.FC = () => {
  return (
    <ForgotFormWrapper>
      <LoginFormForgot />
    </ForgotFormWrapper>
  );
};

export const LoginForm: React.FC<LoginFormProps> = ({authData, successUpdated}) => {
  const {onSubmit, error, resetError, loading} = authData;
  const [form] = Form.useForm();
  const [stateButton, setStateButton] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [pv, setPV] = useState(false);
  const togglePV = () => setPV((prev) => !prev);

  const onFinish = (values: any) => {
    onSubmit(values);
  };

  const handleChange = (inputValue: {email: string; password: string}): void => {
    if (inputValue.email) {
      setEmailIsValid(validateEmail(inputValue?.email && inputValue?.email.toLowerCase()));
    }
    if (inputValue.password) {
      setPasswordIsValid(validatePassword.test(inputValue?.password && inputValue?.password));
    }
  };

  useEffect(() => {
    const isCredentialsValid = emailIsValid;
    if (isCredentialsValid !== stateButton) setStateButton(isCredentialsValid);
  }, [emailIsValid, passwordIsValid]);

  return (
    <LoginFormWrapper
      form={form}
      onFinish={onFinish}
      onChange={resetError}
      onValuesChange={handleChange}
      validateMessages={validateMessages}
      layout={'vertical'}>
      <LoginFormTitle>Promo Codes Client Portal</LoginFormTitle>
      <LoginFormSubtitle>Log into your account by providing the information below</LoginFormSubtitle>
      <StyledAuthFormItem
        name={['email']}
        rules={[
          {required: true, message: 'Email Address is required!'},
          {
            type: 'email',
          },
        ]}>
        <StyledAuthInput placeholder="Enter email" />
      </StyledAuthFormItem>
      <PasswordWrapper>
        <StyledAuthFormItem rules={[{required: true, message: 'Password is required!'}]} name={['password']}>
          <StyledAuthInput type={pv ? 'text' : 'password'} placeholder="Enter password" />
        </StyledAuthFormItem>
        <VisibleIcon onClick={togglePV}>{pv ? <EyeInvisibleOutlined /> : <EyeOutlined />}</VisibleIcon>
      </PasswordWrapper>
      {error && (
        <ErrorLine>
          <ErrorText>{error}</ErrorText>
        </ErrorLine>
      )}
      <ButtonsWrapper>
        <StyledAuthFormItem>
          <SubmitBtn htmlType="submit" disabled={!stateButton} loading={loading}>
            Log in
          </SubmitBtn>
        </StyledAuthFormItem>
        <PasswordLabel />
      </ButtonsWrapper>
      <StyledLine>
        <StyledOr>Or</StyledOr>
      </StyledLine>
      <LoginLinkButton to={route.magicLink.path}>Log in with a Magic Link</LoginLinkButton>
      {successUpdated && (
        <EmailResultLine>
          <ResultText>Success, your password has been updated.</ResultText>
        </EmailResultLine>
      )}
    </LoginFormWrapper>
  );
};

const PasswordWrapper = styled.div`
  position: relative;
`;

const VisibleIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: 10px;
  top: 5px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;
