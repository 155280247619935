import React, {PropsWithChildren, useEffect, useState} from 'react';
import styled from 'styled-components';
import {SvgIcon} from '../Common/Icon';
import debounce from 'debounce';
import {GuestSearchResult} from './GuestsSearch';

type PropsT = PropsWithChildren & {
  header: React.ReactNode;
  hideFooter?: boolean;
  guestCount?: number;

  searchBy?: string;
  searchedCount?: number;
};

const header_height = 30;
const footer_height = 64;

export const GuestListLayout: React.FC<PropsT> = ({
  children,
  header,
  hideFooter,
  guestCount,
  searchBy,
  searchedCount,
}) => {
  return (
    <Layout>
      <Header>{header}</Header>
      <GuestSearchResult searchBy={searchBy} searchedCount={searchedCount} />
      <Body id="guest-list-scroll">{children}</Body>
      {!hideFooter && <Footer guestCount={guestCount} />}
    </Layout>
  );
};

const Footer = ({guestCount}: {guestCount?: number}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const list = document.getElementById('guest-list-scroll');
    if (!list || !guestCount || guestCount <= 3) return setScrolled(true);
    const rects = list.getBoundingClientRect();
    if (rects?.height >= list?.scrollHeight) return setScrolled(true);

    const diff = list?.scrollHeight - rects?.height - 15; //calc offset to hide the footer
    const listener = () => {
      if (list?.scrollTop > diff) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const debouncedCb = debounce(listener, 300);

    list.addEventListener('scroll', debouncedCb);
    return () => list.removeEventListener('scroll', debouncedCb);
  }, [guestCount]);

  if (scrolled) return null;
  return (
    <>
      <FooterWrapper>
        Scroll to view more
        <ArrowIcon type="solidArrowDown" />
      </FooterWrapper>
    </>
  );
};

const Header: React.FC<PropsWithChildren> = ({children}) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const list = document.getElementById('guest-list-scroll');
    if (!list) return;
    const listener = () => {
      if (list?.scrollTop > 40) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    const debouncedCb = listener;

    list.addEventListener('scroll', debouncedCb);
    return () => list.removeEventListener('scroll', debouncedCb);
  }, []);

  return (
    <>
      <HeaderWrapper>{children}</HeaderWrapper>
      {scrolled && <UpperShadow />}
    </>
  );
};

const Layout = styled.div`
  box-sizing: border-box;
  margin-top: 10px;
`;

const HeaderWrapper = styled.div`
  height: ${header_height}px;
  margin-bottom: 10px;
`;
const Body = styled.div`
  position: relative;

  width: 100%;
  overflow-x: clip;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
  box-sizing: border-box;
  padding-bottom: 20px;
  max-height: ${window.innerHeight / 1.7}px;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const UpperShadow = styled.div`
  width: 100%;
  position: absolute;
  height: 41px;
  margin-top: -10px;
  background: linear-gradient(180deg, #ffffff 52.56%, rgb(255 255 255 / 19%) 104.84%);
  z-index: 1;
`;

const FooterWrapper = styled.div`
  width: 100%;
  height: ${footer_height}px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  bottom: 0;
  left: 0;

  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;

  border-top: 1px solid #e6e7e8;
  background-color: #fff;
`;

const ArrowIcon = styled(SvgIcon)`
  margin-left: 6px;
  width: 20px;
  height: 20px;
`;
