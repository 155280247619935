import React from 'react';
import {route} from '../../constants/routes';
import {User} from '../../types/auth';
import {
  ContinueButton,
  FootNote,
  FootNoteBoldText,
  FootNoteText,
  WelcomeContent,
  WelcomeContentText,
  WelcomeContentWrapper,
  WelcomePageWrapper,
  WelcomeTitle,
  // StyledFileLink,
} from './styles';
import {CodeGroupT} from '../../queries/types/groups';
import {TITLE} from '../../ui-kit/constants';

type WelcomePageProps = {
  user: User | null;
  group?: CodeGroupT;
};

export const WelcomePage: React.FC<WelcomePageProps> = ({group}) => {
  const name = group?.contactPerson;
  return (
    <WelcomePageWrapper>
      <WelcomeContentWrapper>
        <WelcomeTitle>Welcome To {TITLE} Promo Code Portal</WelcomeTitle>
        <WelcomeContent>
          <WelcomeContentText>Hi {name},</WelcomeContentText>
          <WelcomeContentText>
            Here’s your promo code portal from {TITLE}. To use this, provide us with your guests’ names and emails, and
            we’ll send them an email with their code and a link to purchase tickets.
          </WelcomeContentText>
          <WelcomeContentText>You have been issued {group?.codesUploaded || 0} Codes.</WelcomeContentText>
          <ContinueButton type={'primary'} to={route.groups.path}>
            Continue
          </ContinueButton>
          <FootNote>
            <FootNoteText>By continuing, I agree to {TITLE} </FootNoteText>
            <FootNoteBoldText href={'https://enchantchristmas.com/terms-conditions/'} target={'_blank'}>
              terms and conditions{' '}
            </FootNoteBoldText>
            <FootNoteText>and </FootNoteText>
            <FootNoteBoldText href={'https://enchantchristmas.com/privacy-policy/'} target={'_blank'}>
              privacy policy.
            </FootNoteBoldText>
          </FootNote>
        </WelcomeContent>
      </WelcomeContentWrapper>
    </WelcomePageWrapper>
  );
};
