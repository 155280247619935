import React from 'react';
import {GroupView} from '../../components/Groups/View/View';
import {useNavigate, useParams} from 'react-router-dom';
import {
  useGetCodeGroup,
  useGetGroups,
  useGetListPromoCodes,
  usePromoCodesActions,
  useSendCode,
  useSendGuestEmail,
} from '../../hooks/groups';
import {useSetEmails, useUpdateTarget, useUploadEmails} from '../../hooks/emails';
import {route} from '../../constants/routes';
import {GroupFooterContainer} from '../Layout/Footer';
import {useHelpModal} from '../../hooks/onboarding';
import {Onboarding} from './Onboarding';

export const Group = () => {
  const {id} = useParams();
  const navigate = useNavigate();
  const {group, loading, refetch: gRefetch, notFound} = useGetCodeGroup(id);
  const {groupsOptions} = useGetGroups();
  const {
    tableData,
    loading: listLoading,
    refetch,
    notSentEmails,
    searchPromoCodes,
    usedCodes,
    tableView,
    onSetTableView,
    downloadCSV,
  } = useGetListPromoCodes(id);
  const handleRefetch = () => {
    gRefetch();
    refetch();
  };
  const {upload} = useUploadEmails(id ? [id] : [], handleRefetch);
  const {sendGuest} = useSendGuestEmail(id, handleRefetch);
  const {sendCode} = useSendCode(id, handleRefetch);
  const {onUpdate, onDeletePromoCode, onDelete} = usePromoCodesActions(id, handleRefetch);
  const emailsData = useSetEmails();
  const groupRedirect = (value?: any) => value && navigate(route.groupView.get({id: String(value)}));
  const {updateTarget} = useUpdateTarget({
    groupId: id,
    onSuccess: refetch,
    refetchGroup: gRefetch,
    emails: emailsData.emails,
    usedCodes,
  });
  const modals = useHelpModal();
  // const {sendAll} = useMultipleSend({groupId: id, onSuccess: emailsData.reset});
  // const onBulk = async () => {
  //   await sendAll(emailsData.emails);
  // };
  if (notFound) return null;
  return (
    <>
      <Onboarding />
      <GroupView
        code={group}
        codeLoading={loading}
        tableData={tableData}
        listLoading={listLoading}
        onUpload={upload}
        onDeletePromoCode={onDeletePromoCode}
        onSendCode={sendCode}
        onSendGuest={sendGuest}
        onUpdate={onUpdate}
        groupsOptions={groupsOptions}
        onGroupRedirect={groupRedirect}
        emailsData={emailsData}
        updateTarget={updateTarget}
        searchPromoCodes={searchPromoCodes}
        refetch={handleRefetch}
        onDelete={onDelete}
        setTableView={onSetTableView}
        tableView={tableView}
        modals={modals}
        downloadCSV={downloadCSV}
      />
      <GroupFooterContainer onSuccess={handleRefetch} sendCount={notSentEmails} />
    </>
  );
};
