import styled, {css} from 'styled-components';
import {Card, Popover, Select, Tag, Typography} from 'antd';
import {Media} from '../../../ui-kit/theme/breakpoints';
import {Button, InfoButton, InfoButtonProps} from '../../../ui-kit/Button';
import Input from '../../../ui-kit/Input';
import {PrimaryTable} from '../../../ui-kit/Table';
import {FileUpload} from '../../../ui-kit/Input/FileUpload';
import {QuestionCircleFilled} from '@ant-design/icons';
import {CSVLink} from 'react-csv';

const {Text} = Typography;

export const StyledFileUpload = styled(FileUpload)`
  width: 1000px;
`;

const buttonStyle = css`
  border: none;
  background-color: transparent;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
  padding-right: 0;
  :hover {
    background-color: transparent;
  }
`;

export const PageWrapper = styled.div`
  font-family: 'Proxima Nova';
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 64px 190px 32px 190px;
  ${Media.down.l} {
    padding: 15px 20px;
  }
  padding-bottom: 100px;
`;

export const TitleLine = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const ModalDescr = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
`;

export const CardInfoRow = styled.div`
  margin-bottom: 4px;
  display: flex;
  align-items: center;
`;

export const CardInfoValue = styled(Text)`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 14px;
  line-height: 22px;
`;

export const TableCardsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const TableCardsOptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const SendCodesButtonWrapper = styled.div``;

export const CardsRow = styled.div`
  display: flex;
  ${Media.down.l} {
    flex-wrap: wrap;
  }
`;

export const TableTitleCard = styled(Card).attrs({
  size: 'small',
})`
  margin-right: 14px;
`;

export const CardDateInfoLabel = styled(Text).attrs({
  type: 'secondary',
})`
  font-weight: ${({theme}) => theme.font.weight.regular};
  font-size: 14px;
  line-height: 22px;
  margin-right: 6px;
  width: 70px;
`;

export const ActionBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  color: ${({theme}) => theme.palette.common.brown};
`;

export const ActionColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const MenuAction = styled.div<{$danger?: boolean}>`
  color: ${({theme, $danger}) => (!$danger ? theme.palette.text.primary : theme.palette.common.red)};
`;
export const CustomInfoButton = styled(InfoButton)`
  margin-right: 6px;
`;

export const CodeColumn = styled.div``;
export const CodeColumnLine = styled.div`
  display: flex;
  align-items: center;
`;
export const CodeNames = styled.div`
  font-size: 11px;
  margin-left: 8px;
`;

export const CodeInput = styled(Input)`
  max-width: 200px;
  height: 32px;
`;

export const ViewTable = styled(PrimaryTable)`
  & .ant-table-container table > thead > tr:first-child th:last-child {
    text-align: right;
  }
  & .ant-table-tbody tr td:first-of-type {
    width: 120px;
  }
  & .ant-table-tbody tr td:nth-child(2) {
    width: 210px;
  }
  & .ant-table-tbody tr td:nth-child(3) {
    width: 100px;
  }
  & .ant-table-tbody tr td input {
    width: 210px;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ValueItem = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.regular};
`;

export const ValueNameItem = styled.span`
  font-size: 14px;
  line-height: 20px;
  font-weight: ${({theme}) => theme.font.weight.bold};
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const UploadButton = styled(Button)`
  margin-bottom: 8px;
  width: 135px;
`;

export const StyledSelect = styled(Select)`
  width: 100px;
`;

export const Centered = styled.div`
  text-align: center;
  span {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    font-size: 10px;
  }
`;

export const SearchBtn = styled(Button).attrs({
  type: 'text',
})`
  ${buttonStyle}
  span > svg {
    color: ${({theme}) => theme.palette.common.black};
    font-size: 20px;
  }
  background-color: transparent;
  :active,
  :hover,
  :focus {
    background-color: transparent;
  }
`;

export const SearchWrapper = styled.div`
  padding: 15px 19px;
  background-color: ${({theme}) => theme.palette.common.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
  display: flex;
`;

export const EnterButton = styled(Button)`
  width: 129px;
  height: 36px;
  border-radius: 40px;
  margin-left: 8px;
`;

export const SearchInput = styled(Input)`
  border-radius: 4px;
  min-width: 300px;
  height: 36px;
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
    box-shadow: none;
  }
`;

export const SearchResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const ResultLabel = styled.span``;

export const ClearButton = styled.button`
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
`;

export const MenuActionIt = styled.div<{$danger?: boolean}>`
  color: ${({theme, $danger}) => (!$danger ? theme.palette.text.primary : theme.palette.common.red)};
`;

export const QuestionCircleStyled = styled(QuestionCircleFilled)`
  width: 20px;
  height: 20px;
`;

export const WrapperLinks = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const ViewMoreButton = styled(ClearButton)`
  min-width: 90px;
  margin-right: 10px;
`;

export const CodeListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.palette.common.white};
  padding: 8px;
  border-radius: 2px;
  margin-right: 80px;
  max-height: 140px;
  overflow: scroll;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
`;

export const DropDownText = styled.span`
  font-size: 14px;
  line-height: 20px;
`;

export const TableViewSelect = styled(Select)`
  & .ant-select-arrow {
    /* margin-top: -13px; */
  }
`;

export const AboveTableLine = styled.div`
  position: relative;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeadLine = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const HelpRow = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const HelpDescription = styled.div`
  width: 360px;
`;

export const HelpPopover = styled(Popover)`
  border-radius: 10px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ContentWrapper = styled.div<{$no_mg?: boolean}>`
  margin-top: ${({$no_mg}) => ($no_mg ? '0' : '25px')};
  p {
    margin-top: 0;
    padding-top: 0;
  }
  b {
    font-weight: bold;
    font-size: 16px;
  }
`;

export const GoNextButton = styled(Button)`
  width: 100%;
  margin-top: 30px;
  height: 40px;
`;

export const MethodsWrapper = styled.div`
  margin-top: 25px;
`;

export const MethodButton = styled.button`
  width: 400px;
  height: 105px;
  padding: 26px 16px 10px 16px;
  box-sizing: border-box;
  border-radius: 10px;
  display: flex;
  background-color: #becccc;

  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 16px;
  img {
    display: block;
    width: auto;
    margin-right: 20px;
    margin-left: 10px;
  }
  position: relative;
`;

export const ButtonLabel = styled.div`
  padding: 4px 8px;
  border-radius: 5px;
  background-color: #227b75;
  position: absolute;
  top: -10px;
  left: 10px;
  color: #fff;
  font-size: 12px;
`;

export const MethodWrap = styled.div``;

export const MethodTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  margin-bottom: 6px;
`;

export const MethodDescr = styled.div`
  font-size: 12px;
  text-align: left;
  line-height: 18px;
`;

export const Video = styled.iframe`
  width: 100%;
  height: 220px;
  margin: 0 auto;
  background-color: #d9d9d9;
  margin-bottom: 30px;
  border: none;
`;

export const ButtonsPair = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const Delimetr = styled.div`
  margin: 20px 0;
  height: 1px;
  width: 100%;
  background-color: #cccfd0;
  gap: 10px;
`;

export const DownloadButton = styled(Button)`
  width: 49%;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    display: block;
    margin-right: 10px;
  }
  font-size: 12px;
  margin-right: 0 !important;
  height: 40px;
`;

export const WideButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 15px;
`;

export const MoreInfoRow = styled.div`
  margin-top: 10px;
  b {
    font-size: 14px;
  }
  a {
    font-weight: bold;
    font-size: 14px;
    color: #937e6b;
    text-decoration: underline #937e6b;
  }
`;

export const SuccessBanner = styled.div`
  width: 100%;
  padding: 26px 20px;
  box-sizing: border-box;
  position: relative;
  font-size: 16px;
  background-color: ${({theme}) => theme.palette.common.skyBlue};
  margin-bottom: 10px;
  p {
    padding: 3px 0;
    margin: 0;
  }
  border-bottom: 1px solid ${({theme}) => theme.palette.common.midnightBlue};
  box-shadow: 1px 3px 4px 1px rgba(0, 0, 0, 0.2);
`;

export const CloseSuccess = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
`;

export const SizeContent = styled.div<{size: string}>`
  .ant-upload-list-item-name::after {
    ${({size}) => size && `content: "${size}"`};
    font-size: 11px;
    padding-left: 4px;
  }
`;

export const GetCSVbutton = styled(Button)<InfoButtonProps>`
  min-width: 150px;
  margin-bottom: 10px;
`;

export const DownloadCSVButton = styled(CSVLink)`
  display: none;
  opacity: 0;
`;

export const ValidationTag = styled(Tag)<{$valid?: boolean}>`
  background-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  border-color: ${({theme, $valid}) => ($valid ? theme.palette.common.darkGreen : theme.palette.common.red)};
  color: ${({theme}) => theme.palette.common.white};
  border-radius: 4px;
  height: 25px;
  width: 70px;
  padding-top: 2px;
  padding-left: 10px;
`;

export const MaxCodesLabel = styled(ValidationTag)`
  position: absolute;
  bottom: 3px;
  right: 2px;
  width: auto;
  background-color: ${({theme}) => theme.palette.common.redBrown};
  border: none;
  opacity: 0.9;
  align-items: center;
  display: flex;

  height: 16px;
  font-size: 10px;
`;
