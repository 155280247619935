import styled from 'styled-components';
import {Typography, Form} from 'antd';
import {Button} from '../../ui-kit/Button';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {Media} from '../../ui-kit/theme/breakpoints';
const {Text} = Typography;
import {LinkButton} from '../../ui-kit/Button';

export const PageWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.white};
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  min-height: 100vh;
  font-family: Proxima Nova;
  ${Media.down.l} {
    flex-direction: column;
    min-height: unset;
    height: 100%;
  }
`;

export const MagicLinkFormContainer = styled.div`
  margin-top: 90px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${Media.down.l} {
    margin-top: 15px;
  }
`;

export const MagicLinkFormWrapper = styled(Form)`
  width: 100%;
  max-width: 375px;
`;

export const FormTitle = styled(Text)`
  font-family: Majesti Banner;
  font-size: 24px;
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
  line-height: 32px;
`;

export const SubmitBtn = styled(Button).attrs({
  type: 'primary',
})`
  width: 205px;
  margin-top: 10px;
  height: 38px;
  font-size: 16px;
  font-weight: ${({theme}) => theme.font.weight.regular};
  opacity: 1;
  :disabled {
    color: ${({theme}) => theme.palette.common.white};
    background: ${({theme}) => theme.palette.common.brown};
    opacity: 0.5;
  }
  ${Media.down.l} {
    display: block;
    margin: 0 auto;
  }
`;

export const SuccessBlockWrapper = styled.div`
  width: 100%;
  max-width: 370px;
  padding-top: 15px;
`;

export const SuccessTitle = styled(Text)`
  font-family: Majesti Banner;
  font-size: 24px;
  line-height: 32px;
  font-weight: ${({theme}) => theme.font.weight.mediumBold};
  margin-bottom: 12px;
  display: block;
`;

export const DescrText = styled(Text)`
  font-size: 14px;
  line-height: 22px;
  display: block;
  margin-top: 8px;
  margin-bottom: 24px;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 200px;
`;

export const RightPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  ${Media.down.l} {
    padding: 0px 15px 80px 15px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const FooterWrapper = styled(Footer)`
  height: 20px;
  padding-bottom: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 16px;
`;

export const ErrorLine = styled.div`
  color: ${({theme}) => theme.palette.common.redBrown};
`;

export const StyledLine = styled.div`
  position: relative;
  border-bottom: 1px solid #9a9fa2;
  margin: 34px 0;
`;

export const StyledOr = styled.span`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 24px;
  background-color: ${({theme}) => theme.palette.common.white};
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
`;

export const LoginLinkButton = styled(LinkButton)`
  width: 174px;
  height: 40px;
  background-color: transparent;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #495258;
  ${Media.down.l} {
    display: block;
    margin: 0 auto;
  }
`;
